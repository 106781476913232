import * as React from 'react';
import '../../styles/App.less';
import {Helmet} from 'react-helmet';
import {getCookie, setUTMCookie} from '../../utils/CookieUtil';
import {PageProps} from 'gatsby';
import {AdminLayout} from '../../components/Admin/AdminLayout';
import {Result} from 'antd';
import {OrganizationSelection} from '../../components/OrganizationSelection/OrganizationSelection';

// markup
const IndexPage = (props: PageProps) => {
  // @ts-ignore
  const hasAdminAccess = props.location.state?.hasAdminAccess || getCookie('hasAdminAccess');

  if (!hasAdminAccess)
    return (
      <main className={'main-holder'}>
        <div className='main-error'>
          <Result status='error' title='Permission Denied' subTitle='You do not have permission to access this page' />
        </div>
      </main>
    );

  setUTMCookie(props);
  return (
    <>
      <Helmet htmlAttributes={{lang: 'en'}}>
        <meta charSet='utf-8' />
        <title>Cribl.Cloud - Select Organization</title>
        <link rel='canonical' href='https://cribl.cloud/admin' />
      </Helmet>
      <main key={'admin'} className={'main-holder'}>
        <AdminLayout>
          <OrganizationSelection {...props} />
        </AdminLayout>
      </main>
    </>
  );
};

export default IndexPage;
