import * as React from 'react';
// @ts-ignore
import * as styles from './admin.module.less';
import {Layout, Menu} from 'antd';
import {Link} from 'gatsby';
const {Content} = Layout;

type AdminLayoutProps = {
  children: React.ReactNode;
};

export const AdminLayout = ({children}: AdminLayoutProps) => {
  return (
    <Layout>
      <Menu selectedKeys={[location?.pathname]} mode='horizontal' theme='dark'>
        <Menu.Item key='/admin'>
          <Link to={'/admin'} state={{hasAdminAccess: true}}>
            Select Organization
          </Link>
        </Menu.Item>
        <Menu.Item key='/admin/notifications'>
          <Link to={'/admin/notifications'} state={{hasAdminAccess: true}}>
            Notifications
          </Link>
        </Menu.Item>
      </Menu>
      <Content className={styles.content}>{children}</Content>
    </Layout>
  );
};
